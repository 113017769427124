import { motion } from "framer-motion";
import {
  IFiftyFiftyCarouselFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import {
  ContentfulCenteredImage,
  ForcedAspectRatio,
  ImageSlowScroll,
} from "../shared";

export type ZoomOutProps = {
  fields: SpecificLocaleFields<IFiftyFiftyCarouselFields>;
  lastIndex: SpecificLocaleFields<number | null>;
  newIndex: SpecificLocaleFields<number>;
  sizes?: any;
  [k: string]: any;
};

const ZoomOut = ({
  fields,
  lastIndex,
  newIndex,
  ...otherProps
}: ZoomOutProps) => {
  if (!fields.items) return null;
  return (
    <div
      className="relative col-span-6 flex w-full overflow-hidden lgmax:hidden"
      {...otherProps}
    >
      {fields.items?.map((item, index) => (
        <ForcedAspectRatio
          ratio={[3, 4]}
          className={"overflow-hidden"}
          key={item.sys.id}
        >
          <motion.div
            className={"absolute left-0 h-full w-full"}
            style={{ zIndex: fields.items!.length - index }}
            animate={
              index === newIndex
                ? "selected"
                : index < newIndex
                ? "onTop"
                : "under"
            }
            variants={{
              under: { scale: 1.2, x: 0 },
              selected: { scale: 1, x: 0 },
              onTop: { scale: 1, x: "100%" },
            }}
            transition={{ duration: 1.2, type: "tween", ease: "easeInOut" }}
          >
            <ImageSlowScroll>
              {item.fields.image && (
                <ContentfulCenteredImage
                  key={item.sys.id}
                  className={`h-full w-full`}
                  image={item.fields.image.fields}
                />
              )}
            </ImageSlowScroll>
          </motion.div>
        </ForcedAspectRatio>
      ))}
    </div>
  );
};
export default ZoomOut;
