import { ClientSide } from "~/@types";
import { IImageFields } from "~/@types/generated/contentful";
import { getFrontifyImageURL } from "./getFrontifyImage";

//transformations = "?twic=v1/resize=/background="
export const getTwicPicsURL = (
  image: ClientSide<IImageFields>,
  transformations: string = ""
) => {
  const TWICPICS_DOMAIN = "https://tbi-assets.twic.pics";
  const ffySplitter = "frontify-enterprise-files-eu";
  const ctfSplitter = "75ila1cntaeh";

  const url = image?.frontifyImage
    ? `${TWICPICS_DOMAIN}/ffy${
        getFrontifyImageURL(image).split(ffySplitter)[1]
      }?fm=webp${transformations}`
    : `${TWICPICS_DOMAIN}/ctf${
        image?.image?.fields?.file?.url.split(ctfSplitter)[1]
      }?fm=webp${transformations}`;

  return url;
};

export const getTwicPicsURLAsset = (url: string) => {
  const TWICPICS_DOMAIN = "https://tbi-assets.twic.pics";
  const ctfSplitter = "75ila1cntaeh";

  return `${TWICPICS_DOMAIN}/ctf${url.split(ctfSplitter)[1]}?fm=webp`;
};

export const getTwicPicsURLFromSrc = (
  url: string,
  transformations: string = ""
) => {
  const TWICPICS_DOMAIN = "https://tbi-assets.twic.pics";
  const ctfSplitter = "75ila1cntaeh";

  return `${TWICPICS_DOMAIN}/ctf${url.split(
    ctfSplitter
  )}?fm=webp${transformations}`;
};
