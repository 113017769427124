import { useEffect, useState } from "react";
import { algoliaClient } from "./../../components/search/AlgoliaProvider";
import { ALGOLIA_INDICES } from "~/config";
import { Hit as AlgoliaHit, Hit } from "instantsearch.js/es/types";

type UseRelatedInsightsReturn = {
  data: AlgoliaHit[] | null;
  loading: boolean;
  error: Error | null;
};

/**
 * Custom React Hook to fetch related insights using Algolia's search method.
 * Returns insights that have at least one matching tag slug from the provided tags.
 * @param tagSlugs Tag slugs to filter insights.
 * @param opts Optional options (e.g., disable fetching).
 * @returns An object containing the data, loading state, and error if any.
 */
export function useRelatedInsights(
  tagSlugs: string[],
  opts?: { disabled?: boolean }
): UseRelatedInsightsReturn {
  const [data, setData] = useState<Hit<AlgoliaHit>[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (opts?.disabled || tagSlugs.length === 0) {
      setData(null);
      setLoading(false);
      return;
    }

    const fetchRelatedInsights = async () => {
      setLoading(true);
      try {
        // Create a filter string to match any of the tag slugs
        const filters = tagSlugs
          .map((slug) => `tags.slug:"${slug}"`)
          .join(" OR ");
        const response = await algoliaClient
          .initIndex(ALGOLIA_INDICES.insights)
          .search<AlgoliaHit>("", {
            filters,
            hitsPerPage: 3,
          });

        setData(response.hits.length > 0 ? response.hits : null);
      } catch (error) {
        console.error("Error fetching related insights:", error);
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchRelatedInsights();
  }, [tagSlugs, opts]);

  return { data, loading, error };
}
