import { ClientSide } from "~/@types";
import { IImageFields } from "~/@types/generated/contentful";

export const getFrontifyImageURL = (
  imageField: ClientSide<IImageFields>,
  width?: number
) => {
  if (imageField.frontifyImage) {
    const fronityUrlObject = new URL(imageField.frontifyImage[0].src);
    fronityUrlObject.search = width ? `width=${width}` : "";
    return fronityUrlObject.toString();
  }

  return "";
};
