import clsx from "clsx";
import { useScroll, useTransform, motion } from "framer-motion";
import { Fragment, ReactNode, useRef } from "react";
import { ClientSide } from "~/@types";
import { INewsletterFields } from "~/@types/generated/contentful";
import ContentfulCenteredImage from "../ContentfulCenteredImage";
import NewsletterButton from "./NewsletterButton";
import { useWindowSize } from "react-use";
import { TextCta } from "../../ctas";
import { Arrow } from "../../icons";

type NewsletterProps = {
  fields: ClientSide<INewsletterFields>;
};

interface ParallaxProps {
  children: ReactNode;
  reverse?: boolean;
  className?: string;
}

function ParallaxText({ children, className = "", reverse }: ParallaxProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { scrollY } = useScroll();
  const { height } = useWindowSize();
  const x = useTransform(scrollY, () => {
    if (ref.current) {
      const { top } = ref.current.getBoundingClientRect();

      let num = (top / height) * 10;
      if (reverse) {
        num = num * -1;
        num = num - 25;
      } else {
        num = num - 50;
      }

      const pcnt = `${num.toFixed(2)}%`;
      return pcnt;
    }
    return 0;
  });

  return (
    <div
      ref={ref}
      id={reverse ? "reverse" : "normie"}
      className={clsx(
        className,
        "flex flex-nowrap overflow-hidden whitespace-nowrap"
      )}
    >
      <motion.div className="flex flex-nowrap whitespace-nowrap" style={{ x }}>
        {children}
        {children}
        {children}
        {children}
      </motion.div>
    </div>
  );
}

export default function NewsletterComponent({ fields }: NewsletterProps) {
  return (
    <div
      data-content-type="newsletter"
      data-module-title={fields.moduleTitle}
      className={clsx({
        "w-full": true,
        "overflow-hidden": true,
        "bg-onyx": fields.grey,
        "text-white": fields.grey,
        "bg-slate": !fields.grey,
        "text-black": !fields.grey,
      })}
    >
      <p className="font-16 lg:font-16 mt-24 text-center uppercase lg:mt-32">
        Newsletter
      </p>
      <ParallaxText reverse className="mt-16 lg:mt-24">
        <div className="lg:h1 h3 flex w-max translate-x-1/4 items-center whitespace-nowrap">
          {fields.topRowTickers.map((ticker) => (
            <Fragment key={ticker.sys.id}>
              <span>{ticker.fields.text}</span>
              <div className="mx-8 aspect-video h-16 lg:h-[86px]">
                <ContentfulCenteredImage
                  image={ticker.fields.image.fields}
                  className="h-full w-full"
                />
              </div>
            </Fragment>
          ))}
        </div>
      </ParallaxText>
      <ParallaxText className="mt-2 lg:mt-8">
        <div className="lg:h1 h3 flex w-max items-center whitespace-nowrap">
          {fields.bottomRowTickers.map((ticker) => (
            <Fragment key={ticker.sys.id}>
              <span>{ticker.fields.text}</span>
              <div className="mx-8 aspect-video h-16 lg:h-[86px]">
                <ContentfulCenteredImage
                  image={ticker.fields.image.fields}
                  className="h-full w-full"
                  ratio="16/9"
                />
              </div>
            </Fragment>
          ))}
        </div>
      </ParallaxText>
      <div className="mx-auto mt-16 mb-24 w-min lg:mt-24 lg:mb-32">
        <NewsletterButton>
          <TextCta
            id="newsletter-button"
            kind={fields.grey ? "white" : "black"}
            icon={<Arrow />}
            label="Sign up"
          />
        </NewsletterButton>
      </div>
    </div>
  );
}
