import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import clsx from "clsx";
import {
  IEventListFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";
import { RoundedCta } from "../ctas";
import { Arrow } from "../icons";
import { ContentfulCenteredImage, ForcedAspectRatio } from "../shared";

type EventsRoutingProps = {
  fields: SpecificLocaleFields<IEventListFields>;
};

// set up slate bg for this

export default function EventsRouting({ fields }: EventsRoutingProps) {
  if (!fields.backgroundColor) fields.backgroundColor = "white";

  return (
    <section
      data-content-type="eventList"
      data-module-title={fields.moduleTitle}
      className={clsx("eventList w-full py-20", {
        "text-white": ["black"].includes(fields.backgroundColor),
        "text-black": ["white", "slate"].includes(fields.backgroundColor),
        "bg-black": fields.backgroundColor === "black",
        "bg-white": fields.backgroundColor === "white",
        "bg-slate": fields.backgroundColor === "slate",
      })}
      style={{
        backgroundColor: fields.colorPalette?.fields.backgroundColor,
        color: fields.colorPalette?.fields.fontColor,
      }}
    >
      <div className="tbi-grid ">
        <div className="col-span-2">
          {fields.eyebrow && (
            <p className="eyebrows col-span-full w-full lg:col-span-2 ">
              {fields.eyebrow}
            </p>
          )}
        </div>
        <div className="col-span-4 lg:col-span-8">
          {fields.items &&
            fields.items.map((item) => (
              <div
                className="grid grid-cols-8 gap-x-5 px-0 lg:mb-10 lg:gap-x-6"
                key={item.sys.id}
              >
                <div className="col-span-full mt-10 lg:col-span-3 lg:mt-0 lg:h-full lg:w-full">
                  {item.fields.image && (
                    <ForcedAspectRatio
                      ratio={[16, 9]}
                      className="h-auto w-full"
                    >
                      <ContentfulCenteredImage
                        className="h-full w-full"
                        image={item.fields.image.fields}
                      />
                    </ForcedAspectRatio>
                  )}
                </div>
                <div className="col-span-full mt-6 auto-rows-max lg:col-span-5 lg:mt-0 lg:grid">
                  <h2
                    className={clsx("h4", {
                      "pb-6 lg:pb-10":
                        !item.fields.body && !item.fields.buttonText,
                      "pb-2": item.fields.body || item.fields.buttonText,
                    })}
                  >
                    {item.fields.heading}
                  </h2>
                  {item.fields.body && (
                    <div
                      className={clsx(
                        "[&_p]:meta [&>:first-child]:mt-0 [&_p]:normal-case",
                        {
                          "pb-6 lg:pb-10":
                            item.fields.buttonText || item.fields.button,
                          "pb-6 lg:pb-0": !item.fields.buttonText,
                        }
                      )}
                    >
                      {documentToReactComponents(
                        item.fields.body,
                        getComponentFromContentfulRichTextEntry
                      )}
                    </div>
                  )}
                  {/* TODO: remove  item.fields.buttonText && item.fields.buttonUrl */}
                  {item.fields.button ? (
                    <RoundedCta
                      label={item.fields.button.fields.buttonText}
                      kind={item.fields.button.fields.type}
                      icon={<Arrow />}
                      to={item.fields.button.fields.buttonUrl}
                      className="self-end lgmax:mb-10"
                      custom={item.fields.button.fields.colorPalette?.fields}
                    />
                  ) : item.fields.buttonText && item.fields.buttonUrl ? (
                    <RoundedCta
                      label={item.fields.buttonText ?? ""}
                      kind={
                        fields.backgroundColor === "black" ? "white" : "black"
                      }
                      icon={<Arrow />}
                      to={item.fields.buttonUrl}
                      className="self-end lgmax:mb-10"
                    />
                  ) : null}
                </div>
                <hr className="col-span-full border border-solid border-[currentColor] lg:mt-10" />
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}
