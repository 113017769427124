import {
  IGridCardsFields,
  SpecificLocaleFields,
} from "~/@types/generated/contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getComponentFromContentfulRichTextEntry } from "~/contentful";
import { ContentfulCenteredImage, ForcedAspectRatio } from "../shared";
import { Fragment } from "react";
import {
  InsightComponentData,
  useComponentData,
} from "~/contexts/ComponentDataProvider";
import clsx from "clsx";
import { RoundedCta } from "../ctas";
import { Arrow } from "../icons";
import useIsMobile from "../utils/useIsMobile";

type CardsGridProps = {
  fields: SpecificLocaleFields<IGridCardsFields>;
  beforeSecondChapter?: boolean;
};

export const bgColorOverrides: any = {
  pistachio: "bg-pistachio",
  tan: "bg-tan",
  mustard: "bg-mustard",
  blue: "bg-blue",
  pink: "bg-pink",
  white: "bg-white",
  slate: "bg-slate",
  black: "bg-black",
};

export default function CardsGrid({
  fields,
  beforeSecondChapter = false,
}: CardsGridProps) {
  const { insight } = useComponentData() as InsightComponentData;
  const isMobile = useIsMobile(1024);
  const numberOfCards = fields.cards ? fields.cards.length : 0;

  return (
    <section
      data-content-type="cardsGrid"
      data-module-title={fields.moduleTitle}
      data-color={fields.backgroundColor}
      className={clsx(
        "grid-cards tbi-grid relative col-span-full mt-10 gap-y-6 py-20 lg:gap-y-20",
        {
          "text-white": insight,
          "text-black": fields.backgroundColor && !insight,
          "!px-0": insight,
        }
      )}
    >
      {fields.heading && (
        <h2 className="z-[1] col-span-full lg:col-span-6">{fields.heading}</h2>
      )}

      {fields.body && (
        <div className="z-[1] col-span-full lg:col-span-4 lg:col-start-8 [&>:first-child]:mt-0">
          {documentToReactComponents(
            fields.body,
            getComponentFromContentfulRichTextEntry
          )}
        </div>
      )}

      {/* DESKTOP */}
      {fields.cards && !isMobile && (
        <div className="z-[1] col-span-full flex flex-col lgmax:hidden">
          <div className="global-grid w-full gap-y-6">
            {fields.cards &&
              fields.cards.map((card, index) => (
                <div
                  key={card.sys.id}
                  className={clsx(
                    "flex flex-col border-b border-solid border-current pb-8",
                    {
                      "col-span-4":
                        fields.gridLayout === "three-column" ||
                        !fields.gridLayout,
                      "col-span-6": fields.gridLayout === "two-column",
                      "border-t pt-6":
                        (fields.gridLayout === "three-column" &&
                          [0, 1, 2].includes(index)) ||
                        (fields.gridLayout === "two-column" &&
                          [0, 1].includes(index)) ||
                        (!fields.gridLayout && [0, 1, 2].includes(index)),
                    }
                  )}
                >
                  <div>
                    {card.fields.image && (
                      <ForcedAspectRatio ratio={[16, 9]} className="mb-6">
                        <ContentfulCenteredImage
                          image={card.fields.image.fields}
                          className="h-full w-full"
                        />
                      </ForcedAspectRatio>
                    )}
                    <h5 className="mb-4">{card.fields.heading}</h5>
                    {card.fields.body && (
                      <div className="[&>:first-child]:mt-0">
                        {documentToReactComponents(
                          card.fields.body,
                          getComponentFromContentfulRichTextEntry
                        )}
                      </div>
                    )}
                  </div>
                  {card.fields.cardCta && (
                    <RoundedCta
                      className="mt-6"
                      kind={card.fields.cardCta?.fields.type}
                      label={card.fields.cardCta?.fields.buttonText}
                      icon={<Arrow />}
                      to={card.fields.cardCta?.fields.buttonUrl}
                    />
                  )}
                </div>
              ))}
          </div>
        </div>
      )}

      {/* MOBILE */}
      {fields.cards &&
        isMobile &&
        fields.cards.map((card, index) => {
          return index < numberOfCards ? (
            <Fragment key={card.sys.id}>
              <div className="z-[1] col-span-4 flex h-full flex-col justify-between lg:hidden">
                <div
                  className={clsx(
                    "flex flex-col justify-between border-b border-solid border-current pb-6",
                    {
                      "border-t pt-6": index === 0,
                    }
                  )}
                >
                  {card.fields.image && (
                    <ForcedAspectRatio ratio={[16, 9]} className="mb-6 ">
                      <ContentfulCenteredImage
                        image={card.fields.image.fields}
                        className="h-full w-full"
                        ratio="16/9"
                      />
                    </ForcedAspectRatio>
                  )}
                  <h5 className="mb-4">{card.fields.heading}</h5>
                  {card.fields.body && (
                    <>
                      {documentToReactComponents(
                        card.fields.body,
                        getComponentFromContentfulRichTextEntry
                      )}
                    </>
                  )}
                  {card.fields.cardCta && (
                    <RoundedCta
                      className="mt-4"
                      kind={card.fields.cardCta?.fields.type}
                      label={card.fields.cardCta?.fields.buttonText}
                      icon={<Arrow />}
                      to={card.fields.cardCta?.fields.buttonUrl}
                    />
                  )}
                </div>
              </div>
            </Fragment>
          ) : null;
        })}
      {beforeSecondChapter && !insight && (
        <span className="absolute -left-[100vw] -right-[100vw] top-0 bottom-0 -z-10 bg-slate" />
      )}
      {insight && (
        <span
          className={clsx(
            "absolute -left-[100vw] -right-[100vw] top-0 bottom-0",
            fields.backgroundColor && bgColorOverrides[fields.backgroundColor]
          )}
          style={{
            backgroundColor: insight.fields.topic?.fields.color,
          }}
        />
      )}
      {!insight && !beforeSecondChapter && fields.backgroundColor && (
        <span
          className={clsx(
            "absolute -left-[100vw] -right-[100vw] top-0 bottom-0",
            bgColorOverrides[fields.backgroundColor]
          )}
        />
      )}
    </section>
  );
}
