import { ClientSide } from "~/@types";
import { IImageFields } from "~/@types/generated/contentful";
import { motion } from "framer-motion";
import { getBgImageStyles } from "../utils/getBgImageStyles";

export interface CenteredImageAspectSwitchOnViewProps {
  image: ClientSide<IImageFields>;
  className?: string;
  bg?: "fade" | "gradient" | "grey";
  style?: any;
  once?: boolean;
  [k: string]: any;
}

export default function CenteredImageAspectSwitchOnView({
  className = "",
  image,
  bg,
  style = {},
  once = false,
  ...otherProps
}: CenteredImageAspectSwitchOnViewProps) {
  return (
    <motion.div
      initial={{
        aspectRatio: "16/9",
      }}
      whileInView={{
        aspectRatio: "3/4",
      }}
      transition={{
        duration: 1,
        ease: "easeInOut",
      }}
      viewport={{ once, amount: "some" }}
      role="img"
      aria-label={image?.alt ?? "background image"}
      className={`${className} aspect-video`}
      {...otherProps}
      style={{
        ...getBgImageStyles(image, bg),
        ...style,
      }}
    >
      {!image && "No image found"}
    </motion.div>
  );
}
