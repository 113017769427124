import { ClientSide } from "~/@types";
import { IImageFields } from "~/@types/generated/contentful";
import { motion } from "framer-motion";
import { getTwicPicsURL } from "../utils/getTwicPicsURL";

export interface CenteredImgZoomInOnViewProps {
  image: ClientSide<IImageFields>;
  className?: string;
  bg?: "fade" | "gradient" | "grey";
  once?: boolean;
  [k: string]: any;
}

export default function CenteredImgZoomInOnView({
  className = "",
  image,
  bg,
  once = false,
  ...otherProps
}: CenteredImgZoomInOnViewProps) {
  return (
    <div className={`${className} overflow-hidden`}>
      <motion.img
        transition={{
          duration: 1,
          ease: "easeInOut",
        }}
        initial={{
          scale: 0.8,
        }}
        whileInView={{ scale: 1 }}
        viewport={{ once, amount: 0.5 }}
        src={getTwicPicsURL(image)}
        alt={image.alt ?? "image"}
        className={className}
        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) =>
          (e.currentTarget.src =
            "https://tbi-assets.s3.eu-west-2.amazonaws.com/all-images/placeholder.png")
        }
        {...otherProps}
      />
    </div>
  );
}
